const theme = {
  "theme-first": {
    logo: "/icons/logo.svg",
    headerLogoClass: "w-[184px] h-9 lg:w-[232px] lg:h-[45px]",
    brandcolorsecondary: "bg-brand-color-secondary",
    toBrand7: "to-brand-gray-7",
    bgDetailInfo: "bg-primary",
    colorContent: "text-[#0774a5]",
    blogBg: "bg-[#0076aa]",
    textBlog: "text-green-400",
    LastMinute: {
      WrapperClass: "",
    },
    header: {
      borderClass: "",
    },
    footerLogoWidth: 258,
    footerBgLogo: "lg:bg-footerBgCircle",
    storyItemBorderColor: "",
    FooterBanner: true,
    DetailVideoBgClass: "bg-brand-color-secondary text-white",
  },
  "theme-second": {
    logo: "/icons/logo2.svg",
    headerLogoClass: "w-[184px] lg:w-[232px] h-[86px]",
    toBrand7: "",
    brandcolorsecondary: "bg-brand-color-primary",
    bgDetailInfo: "bg-primary",
    colorContent: "text-[#ee3b29]",
    blogBg: "bg-[#ed4029]",
    textBlog: "text-black",
    LastMinute: {
      WrapperClass: "mt-3",
    },
    header: {
      borderClass: "bOrderx",
    },
    footerLogoWidth: 140,
    footerBgLogo: "",
    storyItemBorderColor: "border-[#f5d3d9]",
    FooterBanner: false,
    DetailVideoBgClass: "bg-white",
  },
};
export default theme[process.env.NEXT_PUBLIC_THEME];

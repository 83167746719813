import React, { memo } from "react";
import style from "./Header.module.css";
import HeaderLeft from "./Header/HeaderLeft";
import dynamic from "next/dynamic";
import isMobile from "is-mobile";
const HeaderRight = dynamic(() => import("../components/Header/HeaderRight"), {
  ssr: false,
});
const Header = ({ headerContent }) => {
  const isPhone = isMobile();
  return (
    <>
      <div className={"z-50 relative " + style.Header}>
        <div className={"container " + style.Wrapper}>
          <HeaderLeft headerContent={headerContent} />
          {!isPhone ? <HeaderRight /> : null}
        </div>
      </div>
    </>
  );
};
export default memo(Header);

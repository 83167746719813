import "../styles/globals.css";
import "../styles/ListVilla.css";
import StoreProvider from "../context/context";
import React from "react";
import "react-loading-skeleton/dist/skeleton.css";
import Header from "../static/Header.js";
import Structural from "../components/Structural.js";
import PageScripts from "../static/PageScripts.js";
import { TranslationProvider } from "../context/TranslationContext.js";
function MyApp({ Component, pageProps }) {
  return (
    <TranslationProvider>
      <StoreProvider>
        <Structural data={pageProps.data?.result.Structural} />
        <PageScripts data={pageProps.data} />
        {pageProps.data?.RoutingAction != "SinglePayment" && (
          <Header headerContent={pageProps.data?.headerContent} />
        )}
        <Component {...pageProps} />
      </StoreProvider>
    </TranslationProvider>
  );
}

export default MyApp;

import { useContext, createContext } from "react";
const TranslationContext = createContext();
import { language } from "../languages/tr";
export function useTranslation() {
  return useContext(TranslationContext);
}

export function TranslationProvider({ children, translations }) {
  return (
    <TranslationContext.Provider value={language}>
      {children}
    </TranslationContext.Provider>
  );
}

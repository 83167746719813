import React, { useState } from "react";
export const StoreContext = React.createContext(null);
export default function ({ children }) {
  const [currentRoutingTypeId, setCurrentRoutingTypeId] = useState(false);
  const [page, setPage] = useState(false);
  const [mobileBar, setMobileBar] = useState(false);
  const [backdrop, setBackdrop] = useState(false);
  const [favouritesIsOpen, setFavouritesIsOpen] = useState(false);
  const [favourites, setFavourites] = useState(
    typeof window !== "undefined"
      ? localStorage.getItem("Favourites")
        ? JSON.parse(localStorage.getItem("Favourites"))
        : []
      : []
  );

  const context = {
    mobileBar,
    setMobileBar,
    backdrop,
    setBackdrop,
    favouritesIsOpen,
    setFavouritesIsOpen,
    favourites,
    setFavourites,
    currentRoutingTypeId,
    setCurrentRoutingTypeId,
    page,
    setPage,
  };
  return (
    <StoreContext.Provider value={context}>{children}</StoreContext.Provider>
  );
}

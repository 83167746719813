export const homeview = () => {
  window.criteo_q = window.criteo_q || [];
  var deviceType = /iPad/.test(navigator.userAgent)
    ? "t"
    : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
        navigator.userAgent
      )
    ? "m"
    : "d";
  window.criteo_q.push(
    { event: "setAccount", account: 83964 }, // You should never update this line
    { event: "setEmail", email: "" },
    { event: "setSiteType", type: deviceType },
    { event: "viewHome" }
  );
};

export const listing = ({ start, end, kisi, d }) => {
  window.criteo_q = window.criteo_q || [];
  var deviceType = /iPad/.test(navigator.userAgent)
    ? "t"
    : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
        navigator.userAgent
      )
    ? "m"
    : "d";
  window.criteo_q.push(
    { event: "setAccount", account: 83964 }, // You should never update this line
    { event: "setEmail", email: "" },
    { event: "setSiteType", type: deviceType },
    {
      event: "viewSearch",
      din: start ? start : "",
      dout: end ? end : "",
      nbra: kisi ? kisi : "",
      nbrc: 0,
      nbri: 0,
      nbrr: 1,
    },
    { event: "viewList", item: d }
  );
};

export const detail = (id) => {
  window.criteo_q = window.criteo_q || [];
  var deviceType = /iPad/.test(navigator.userAgent)
    ? "t"
    : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
        navigator.userAgent
      )
    ? "m"
    : "d";
  window.criteo_q.push(
    { event: "setAccount", account: 83964 }, // You should never update this line
    { event: "setEmail", email: "" }, // Can be an empty string
    { event: "setSiteType", type: deviceType },
    { event: "viewItem", item: id }
  );
};

export const sendReservation = (
  email,
  reservationdate1,
  reservationdate2,
  kisi,
  id,
  price
) => {
  window.criteo_q = window.criteo_q || [];
  var deviceType = /iPad/.test(navigator.userAgent)
    ? "t"
    : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
        navigator.userAgent
      )
    ? "m"
    : "d";
  window.criteo_q.push(
    { event: "setAccount", account: 83964 }, // You should never update this line
    { event: "setEmail", email: email },
    { event: "setSiteType", type: deviceType },
    {
      event: "viewSearch",
      din: reservationdate1,
      dout: reservationdate2,
      nbra: kisi,
      nbrc: 0,
      nbri: 0,
      nbrr: 1,
    },
    {
      event: "trackTransaction",
      id: id,
      item: [
        { id: id, price: price, quantity: 1 },
        //add a line for each additional line in the basket
      ],
    }
  );
};

export const payment = (
  email,
  tarih,
  tarih2,
  yetiskin,
  cocuk,
  id,
  productId,
  price
) => {
  window.criteo_q = window.criteo_q || [];
  var deviceType = /iPad/.test(navigator.userAgent)
    ? "t"
    : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
        navigator.userAgent
      )
    ? "m"
    : "d";
  window.criteo_q.push(
    { event: "setAccount", account: 83964 }, // You should never update this line
    { event: "setEmail", email: email },
    { event: "setSiteType", type: deviceType },
    {
      event: "viewSearch",
      din: tarih,
      dout: tarih2,
      nbra: yetiskin,
      nbrc: cocuk,
      nbri: 0,
      nbrr: 1,
    },
    {
      event: "trackTransaction",
      id: id,
      item: [
        { id: productId, price: price, quantity: 1 },
        //add a line for each additional line in the basket
      ],
    }
  );
};

import React from "react";
import Script from "next/script";
const Structural = ({ data }) => {
  return (
    data &&
    data.map((item) => (
      <Script
        type="application/ld+json"
        key={item.id}
        id={`Structural_${item.id}`}
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(item.content) }}
      />
    ))
  );
};

export default Structural;

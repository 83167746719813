import { useEffect } from "react";
import Script from "next/script";
import * as criteo from "../scripts/criteo";
import { useRouter } from "next/router";
// import { GTM_ID, pageview } from "../scripts/gtm";
// import * as fbq from "../scripts/fpixel";
// import * as gtag from "../scripts/gtag";
const PageScripts = (ssr) => {
  const router = useRouter();
  useEffect(() => {
    if (ssr.data?.RoutingTypeId == "Home") criteo.homeview();
    if (ssr.data?.RoutingTypeId == "ProductDetail")
      criteo.detail(ssr.data.EntityId);
    // fbq.pageview();
    if (router?.query?.pCode) {
      localStorage.setItem("PromotionCode", router?.query?.pCode);
    }
  }, [ssr]);
  return (
    <>
      <Script src="//static.criteo.net/js/ld/ld.js"></Script>

      {process.env.NEXT_PUBLIC_SITE == 1 && (
        <Script
          id="cookieyes"
          strategy="afterInteractive"
          src="https://cdn-cookieyes.com/client_data/320fae27b7b436140be5324e/script.js"
        ></Script>
      )}

      {/* <Script
        id="ganalyz"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${gtag.GA_TRACKING_ID}', {
                page_path: window.location.pathname,
              });
            `,
        }}
      /> */}

      {/* Google Tag Manager - Global base code */}
      {/* <Script
        id="gtag-base"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
          `,
        }}
      /> */}

      {/* Global Site Code Pixel - Facebook Pixel */}
      {/* <Script
        id="fb-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', ${fbq.FB_PIXEL_ID});
          `,
        }}
      /> */}

      {/* Global Site Tag (gtag.js) - Google Analytics */}
      {/* <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      /> */}

      {/* <Script
        src="https://www.googletagmanager.com/gtag/js?id=UA-186005285-2"
        strategy="afterInteractive"
      ></Script> */}
      {/*
      <Script id="gtag" strategy="afterInteractive">
        {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'UA-186005285-2');`}
      </Script>

      <Script
        src="https://www.googletagmanager.com/gtag/js?id=AW-10825292236"
        strategy="afterInteractive"
      ></Script>

      <Script id="gtag2" strategy="afterInteractive">
        {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'AW-10825292236');`}
      </Script>

      <Script id="facebookpixel" strategy="afterInteractive">
        {`!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '274874157647063');
fbq('track', 'PageView');`}
      </Script>
      <Script id="yandex" strategy="afterInteractive">
        {` (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
      m[i].l=1*new Date();
      for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
      k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
      (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
      ym(90599958, "init", {
            clickmap:true,
            trackLinks:true,
            accurateTrackBounce:true,
            webvisor:true,
            ecommerce:"dataLayer"
      });`}
      </Script> */}
    </>
  );
};

export default PageScripts;
